import React, {useEffect, useRef} from 'react';
import {
    createBrowserRouter,
    RouterProvider,
} from 'react-router-dom';
import App from "./App";
import FindCounselor from "./pages/FindCounselor";
import ListCounselor from "./pages/ListCounselor";
import Support from "./pages/Support";
import Index from "./pages/Index";
import NotFound from "./pages/NotFound";

const Router = () => {
    const scrollContent = useRef(null); // Index 서비스 소개 영역 이동용 ref
    const router = createBrowserRouter([
        // 홈페이지 오픈 전까지
        // {
        //     path: "*",
        //     element: <NotFound />
        // },

        {
            path: "/",
            element: <App scrollContent={scrollContent}/>, // 기본 레이아웃
            errorElement: <NotFound />, // 에러 발생 시 표시될 컴포넌트
            children: [
                {
                    path: '/',
                    element: <Index scrollContent={scrollContent}/>,
                },
                {
                    path: 'views/search',
                    element: <FindCounselor />,
                },
                {
                    path: 'views/list',
                    element: <ListCounselor />,
                },
                {
                    path: 'views/support',
                    element: <Support />,
                },
            ]
        },
    ]);

    return <RouterProvider router={router}/>;
};

export default Router;