import React from 'react';
import styled from "styled-components";

import Logo from "../assets/images/logo-black.svg";
import Preparing from "../assets/images/preparing.svg";

const NotFound = () => {
    return(
        <NotFoundWrap>
            <div className={"wrapper"}>
                <div className={"img"}><img src={Preparing}/></div>
                <div className={"text-box"}>
                    <div className={"title"}>서비스 준비중</div>
                    <div className={"desc"}>
                        현재 페이지 준비 중입니다.<br/>
                        빠른 시일 내에 찾아뵙겠습니다.<br/>
                        감사합니다.
                    </div>
                </div>
                <div className={"logo"}><img src={Logo}/></div>
            </div>
        </NotFoundWrap>
    )
}

const NotFoundWrap = styled.div`
  position:relative;
  display:flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height:100vh;
  
  & .wrapper {
    position:relative;
    text-align: center;
    z-index:10;
    
    & .text-box {
      margin:68px 0 63px;
      color:#000;
      
      & .title {
        font-size:48px;
        font-weight:900;
        line-height:71px;
      }
      
      & .desc {
        margin:30px 0 0;
        font-size:22px;
        font-weight:400;
        line-height:29px;
      }
    }
  }
  
  &::before, 
  &::after {
    content:'';
    display:block;
    position:absolute;
    left:0;
    width:100%;
    height:249px;
  }
  
  &::before {
    top:0;
    background: linear-gradient(0deg, transparent 0%, rgba(205,202,202,1) 100%);
  }
  
  &::after {
    top:auto;
    bottom:0;
    background: linear-gradient(180deg, transparent 0%, rgba(205,202,202,1) 100%);
  }
  
  @media (max-width:999px){
    & .wrapper{
      & .img {
        & img {
          max-width:130px;
        }
      }
      
      & .text-box {
        margin:40px 0;
        
        & .title { font-size:30px; }
        
        & .desc {
          font-size:16px;
          margin:15px 0 0;
        }
      }
      
      & .logo {
        & img { max-width:80px; }
      }
    }
  }
`

export default NotFound;