import React, {useEffect, useRef, useState} from 'react';

import Logo from "../../assets/images/logo_big.png";
import styled from "styled-components";
import {Link, NavLink, useParams} from "react-router-dom";
import RoundButton from "../common/RoundButton";

const Header = ({
        scrollContent,
        setService,
        pathname,
    }) => {
    let [isScroll, setIsScroll] = useState(0);
    let [isActive, setIsActive] = useState(false);
    let [homeActive, setHomeActive] = useState(true);
    useEffect(()=>{
        const isActiveMenu = ()=> {
            if( pathname.pathname === "/" ) {
                setIsScroll(window.scrollY || document.documentElement.scrollTop)
                let contentOffset = scrollContent.current.offsetTop - 300;
                let contentHeight = scrollContent.current.offsetHeight + 200;

                if (isScroll > contentOffset && isScroll < contentOffset + contentHeight) {
                    setIsActive(true);
                    setHomeActive(false);
                } else {
                    setIsActive(false);
                    setHomeActive(true);
                }
            }
        }
        const timer = setTimeout(()=>{
            if( pathname.pathname === "/" ){
                window.addEventListener('scroll', isActiveMenu);
            }
            else {
                setIsActive(false);
                setHomeActive(false);
            }
        },100)
        return () =>{
            if( pathname.pathname === "/" ){
                clearTimeout(timer)
                window.removeEventListener('scroll', isActiveMenu);
            }
        }
    },[isScroll, pathname.pathname])

    const clickService = () => {
        if( scrollContent.current !== null ){
            scrollContent.current.scrollIntoView({behavior:"smooth"})
        }
        setService(true)
    }
    return (
        <HeaderWrap>
            <div className={"cover"}>
                <div className={"logo"}><Link
                    to={"/"}
                    onClick={()=>{
                        pathname.pathname === "/" && window.scrollTo({
                            top : 0,
                            behavior: "smooth"
                        });
                    }}
                ><img src={Logo} alt={"logo"}/></Link></div>
                <div className={"nav"}>
                    <div className={"menu-list"}>
                        <ul>
                            <li className={`home ${homeActive}`}><Link
                                to={"/"}
                                onClick={()=>{
                                    pathname.pathname === "/" && window.scrollTo({
                                        top : 0,
                                        behavior: "smooth"
                                    });
                                }}
                            >홈</Link></li>
                            <li className={`${isActive}`}>
                                <Link
                                    to={"/"}
                                    onClick={()=>{clickService()}}
                                >서비스 소개</Link>
                            </li>
                            <li><NavLink to={"/views/search"}>상담사 찾기</NavLink></li>
                            <li><NavLink to={"https://docs.google.com/forms/d/e/1FAIpQLSfr0C5ZI8h3Qdo1C54Ra8hDczlz6Yif1l8-hjX7Bu2VTcN8zw/viewform"} target="_blank">상담사 등록하기</NavLink></li>
                        </ul>
                    </div>
                    <div className={"contact"}>
                        <RoundButton
                            bgColor={"black"}
                            color={"white"}
                            size={"small"}
                            link={"/views/support"}
                        >문의하기</RoundButton>
                    </div>
                </div>
            </div>
        </HeaderWrap>
    )
}

const HeaderWrap = styled.div`
  position:fixed;
  top:0;
  left:0;
  display:flex;
  justify-content: center;
  align-items: center;
  width:100%;
  margin:50px 0 0;
  z-index:100;
  padding:0 40px;
  
  & .cover {
    display:flex;
    justify-content: space-between;
    align-items: flex-end;
    width:100%;
    max-width:1112px;
    
    & .logo{
      transition:all .3s;
      & a {
        & img {
          display:block;
          width:100%;
          height:auto;
          max-width:120px;
        }
      }
      @media (hover: hover) and (pointer: fine) {
        &:hover {
          opacity: .6;
        }
      }
    }
    
    & .nav {
      display: flex;
      align-items: center;
      
      & .menu-list > ul {
        display:flex;
        justify-content: center;
        align-items:center;
        gap:0 22px;
        
        & > li.true a { font-weight:700; }
        
        & .home { display:none; }
        & a {
          transition:all .3s;
          @media (hover: hover) and (pointer: fine) {
            &:hover {
              color: #92FFCD;
              font-weight: 700;
            }
          }
        }
        & a.active { font-weight:700; }
      }
      
      & .contact {
        margin-left:70px;
        
        & a {
          font-size:14px;
          font-weight:400;
          line-height:21px;
          
          &:hover {
            border:2px solid;
            color:#000;
            background-color:#fff;
            font-weight:500;
          }
        }
      }
    }
  }
  
  &::after {
    content:'';
    display:block;
    position:absolute;
    top:-50px;
    left:0;
    width:100%;
    height:184px;
    z-index: -1;
    background:linear-gradient(0deg, rgba(146,255,205,0) 0%, rgba(255,255,255,1) 100%);
    pointer-events: none;
  }
  
  @media (max-width : 999px){
    margin:0;
    padding:15px 17px 10px;
    background-color:rgba(255,255,255,.8);
    box-shadow:0 1px 3px 0 rgba(0,0,0,.3);
    border-radius:0 0 10px 10px;
    height:83px;
    
    & .cover {
      display:block;
      max-width:100%;
      & .logo {
        & a {
          & object ,img {
            max-width:70px;
          }
        } 
      }
      
      & .nav {
        margin:15px 0 0;
        & .menu-list {
          width:100%;
          & > ul {
            justify-content: space-around;
            gap:0;
            font-size:14px;
            & .home { display:block; }
          }
        }
        
        & .contact {
          display:none;
        }
      }
    }
    
    &::after { display:none; }
  }
`


export default Header;