import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";

import AOS from "aos";
import "aos/dist/aos.css"

import RoundButton from "../components/common/RoundButton";
import LoopWrapper from "../components/layout/LoopWrapper";

import bgColor from "../assets/images/bgColor.png";
import bgColorMo from "../assets/images/bgColor-mo.png";
import Article1 from "../assets/images/article1.svg";
import Article2 from "../assets/images/article2.svg";
import Article3 from "../assets/images/article3.svg";
import Article4 from "../assets/images/article4.svg";
import Article5 from "../assets/images/article5.svg";
import SelectBox from "../components/common/SelectBox";
import {useMediaQuery} from "react-responsive";
import SelectLocation from "../components/layout/SelectLocation";

const Index = ({scrollContent}) => {
    // 모바일인지 체크
    let isMobile = useMediaQuery({
        query : "(max-width:999px)",
    });

    useEffect(()=>{
      AOS.init();
    },[])

    return(
        <Main>
            <div className={"main-wrap"}>
                <BgContent className={"content1"}>
                    <div className={"text-box"}>
                        <h1 className={"title"}
                            data-aos={"fade-up"}
                            data-aos-duration={"1000"}
                        >
                            { !isMobile ?
                                (<>대출은 전문상담사와<br/>
                            빠르고 정확하게 상담하세요</>) :
                                (<>대출은 전문상담사와<br/>
                                빠르고 정확하게<br/> 상담하세요</>)}
                        </h1>
                        <div className={"desc"}
                             data-aos={"fade-up"}
                             data-aos-delay={"200"}
                             data-aos-duration={"1000"}
                        >
                            대출전문상담사는 개개인의 신용을 고려하여<br/>
                            불필요한 신용조회는 줄이고<br/>
                            고객 맞춤 양질의 정보를 제공해요.<br/>
                            <span className={"point-color"}>온라인 조회, 지점 방문 전에 지금 필요한 상담사를 찾아보세요.</span>
                        </div>
                        <div className={"button-wrap"}>
                            <RoundButton
                                bgColor={"black"}
                                color={"white"}
                                size={!isMobile ? "default" : "mobile"}
                                className={"button"}
                                link={"/views/search"}
                            >상담사 찾기</RoundButton>
                        </div>
                    </div>
                    <div
                        className={"article"}
                        data-aos={"zoom-out"}
                        data-aos-duration={"1000"}
                        data-aos-delay={"400"}
                    ><object data={Article1}/></div>
                </BgContent>{/*메인 컨텐츠 01*/}

                <Content>
                    <div className={"content2"}>
                        <div
                            className={"text-box"}
                            data-aos={"fade-up"}
                            data-aos-duration={"1000"}
                        >
                            { !isMobile
                                ? <h1 className={"title"}>
                                    우리지역<br/>
                                    대출 전문 상담사를<br/>
                                    찾아보세요
                                </h1>
                                : <h1 className={"title"}>
                                    지역별<br/>
                                    <span className={"point"}>대출 전문 상담사</span>를<br/>
                                    찾아보세요
                                </h1>
                            }
                            <div className={"desc"}>
                                대출 상담사는 전문 상담 지역이 있어요.<br/>
                                필요한 지역을 검색하여<br/>
                                원하는 금융사를 선택하세요.
                            </div>
                        </div>
                        <div
                            className={"select-cover"}
                            data-aos={"fade-left"}
                            data-aos-duration={"1000"}
                        >
                            <div className={"find-select"}>
                                <SelectLocation
                                    bgColor={"black"}
                                    color={"white"}
                                    size={!isMobile ? "middle" : "mobile"}
                                    link={"/views/list"}
                                    text={"상담사 찾기"}
                                    searchArea={""}
                                />
                            </div>
                            <div className={"article"}><object data={Article2} /></div>
                        </div>
                    </div>
                </Content>{/*메인 컨텐츠 02*/}

                <BgContent className={"content3"} ref={scrollContent}>
                    <div className={"text-box"}>
                        <h1
                            className={"title"}
                            data-aos={"fade-in"}
                        >
                            론N톡은 대출 상담사를<br/>
                            무료로 소개해요
                        </h1>
                        <div
                            className={"desc"}
                            data-aos={"fade-in"}
                        >
                            대출 조회가 어렵고 불편한 고객들은 <b>무료</b>로 상담사를 소개받고, <br/>
                            고객을 만나기 어려웠던 상담사는 자신을 홍보할 수 있는<br/>
                            &nbsp;고객과 상담사가 연결되는 공간 <b>론앤톡</b>입니다.
                        </div>
                        <div className={"article"}><object data={Article3} /></div>
                    </div>
                </BgContent> {/*메인 컨텐츠 03*/}

                <Content className={"content4"}>
                    <div className={"content-cover"}>
                        <div className={"content-box"}>
                            <div
                                className={"main-text"}
                                data-aos={"fade-right"}
                            >
                                <h1 className={"title"}>WHY?</h1>
                                <div className={"desc"}>대출 상담사에게</div>
                            </div>
                            <div
                                className={"list"}
                                data-aos={"fade-left"}
                            >
                                <ul>
                                    <li>
                                        <div className={"title"}>정확하고 안전한 상담</div>
                                        <div className={"desc"}>최소 신용조회로 은행 창구와 동일한 상품을 제안드려요.</div>
                                    </li>
                                    <li>
                                        <div className={"title"}>친절하고 편안한 상담</div>
                                        <div className={"desc"}>적극적인 태도로 고객에게 맞춤 상담을 진행해요.</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                        <div className={"content-box"}>
                            <div
                                className={"main-text"}
                                data-aos={"fade-right"}
                            >
                                <h1 className={"title"}>WHY?</h1>
                                <div className={"desc"}>론앤톡에서</div>
                            </div>
                            <div
                                className={"list"}
                                data-aos={"fade-left"}
                            >
                                <ul>
                                    <li>
                                        <div className={"title"}>인증된 상담사</div>
                                        {
                                            !isMobile
                                                ? <div className={"desc"}>론N톡의 모든 상담사는 은행연합회&금융협회에서 확인하실 수 있는 대출상담사예요.</div>
                                                : <div className={"desc"}>론N톡의 모든 상담사는 은행연합회&금융협회에서<br/> 확인하실 수 있는 대출상담사예요.</div>
                                        }

                                    </li>
                                    <li>
                                        <div className={"title"}>다양한 금융사</div>
                                        <div className={"desc"}>원하는 금융사를 선택해서 상담받을 수 있어요.</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </Content> {/*메인 컨텐츠 04*/}

                <BgContent
                    className={"content5"}
                    data-aos={"fade-in"}
                    data-aos-duration={"1000"}
                >
                    <div className={"content-cover"}>
                        <div className={"text-box"}>
                            <b className={"bold"}>론N톡</b>은<br/>
                            <b>고객과 대출상담사</b><br/>
                            모두를 위한 서비스를<br/>
                            제공합니다.
                        </div>
                        <div className={"button-wrap"}>
                            <RoundButton
                                bgColor={"white"}
                                color={"blue"}
                                size={"default"}
                                link={"/views/search"}
                            ><span>상담사 찾기</span></RoundButton>
                            <RoundButton
                                bgColor={"black"}
                                color={"white"}
                                size={"default"}
                                link={"https://docs.google.com/forms/d/e/1FAIpQLSfr0C5ZI8h3Qdo1C54Ra8hDczlz6Yif1l8-hjX7Bu2VTcN8zw/viewform"}
                                target={true}
                            ><span className={"main-color"}>상담사 등록하기</span></RoundButton>
                        </div>
                    </div>
                    <div className={"article article1"}><object data={Article4} /></div>
                    <div className={"article article2"}><object data={Article5} /></div>
                </BgContent> {/*메인 컨텐츠 05*/}
            </div>
            <LoopWrapper />
        </Main>
    )
}

const Main = styled.div`
  position:relative;
  
  & .text-box {
    width:100%;
    max-width:950px;
    margin:0 auto;
    & .title {
      font-size:41px;
      font-weight:900;
      color:#000;
      line-height:55px;
    }
    
    & .desc {
      font-size:20px;
      font-weight:400;
      line-height:36px;
    }
  }
  
  & .article { pointer-events: none; z-index:10; }
  
  @media (max-width:999px){
    & .text-box {
      & .title {
        font-size:30px;
        line-height:43px;
      }
      
      & .desc {
        font-size:12px;
        line-height:21px;
      }
    }
  }
`

const BgContent = styled.div`
  position:relative;
  border-radius: 50px;
  background:url(${bgColor}) no-repeat center/cover;
  height:800px;
  
  &.content1 {
    & .text-box {
      position:absolute;
      top:50%;
      left:50%;
      transform:translate(-50%, -50%);
      text-align:left;
      
      & .desc {
        margin:32px 0 0;
      }
      & .point-color { color: #0185FF; }
      
      & .button-wrap {
        display:flex;
        margin:68px 0 0;

        & [color="blue"], [color="white"] {
          position:relative;

          & a {
            transition: all .3s;
            background-color:#fff;

            & span { transition: all .3s; }
            &:hover { background-color:transparent; }
            &:hover span { color:#fff; }
          }
          &::after {
            content:'';
            display:block;
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background-image: linear-gradient(90deg, rgba(146,255,205,1) 0%, rgba(37,212,251,1) 100%);
            border-radius:25px;
            z-index:-1;
          }
        }
        & [color="white"] a { background-color:#000; }

        & .main-color {
          background-image: linear-gradient(90deg, rgba(146,255,205,1) 0%, rgba(37,212,251,1) 100%);
          background-clip: text;
          -webkit-background-clip: text;
          color:transparent;
          font-weight:700;
        }
        
        & .button {
          font-size:20px;
          font-weight:700;
        }
      }
    }
    
    & .article {
      position:absolute;
      top:75%;
      right:30%;
    }
    
    &::after {
      content:'';
      display:block;
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:120px;
      background:linear-gradient(0deg, rgba(146,255,205,0) 0%, rgba(255,255,255,1) 100%);
    }

    @media (max-width:999px){
      height:auto;
      padding:84px 0 135px;
      border-radius:0;
      background-image:url(${bgColorMo});
        & .text-box {
          position:static;
          max-width:100%;
          padding:0 25px;
          transform:none;
          margin:54px 0 0;
    
          & .desc { margin:18px 0 0; }
          & .point-color { display:block; margin:18px 0 0; }
          & .button-wrap {
            margin:24px 0 0;
            & .button { font-size:14px; }
          }
        }
    
        & .article {
          top:85%;
          right:3%;
          & object { max-width:300px; } // origin : 170px;
        }
    } // max-width : 999px

    @media (max-width:600px){
        & .article {
          top:92%;
          right:5%;
          & object { max-width:170px; } // origin : 170px;
        }
    }
  }
  
  &.content3 {
    padding:264px 0 218px;
    height:auto;
    
    & .text-box {
      position:relative;
      width:100%;
      max-width:1125px;
      background-color:#000;
      border-radius:124px;
      padding:100px 0 150px;
      margin:0 auto;
      text-align: center;
      
      & .title {
        display:inline-block;
        background-image: linear-gradient(90deg, rgba(146,255,205,1) 20%, rgba(37,212,251,1) 100%);
        background-clip: text;
        color:transparent;
        -webkit-background-clip: text;
      }
      
      & .desc {
        color:#fff;
        margin:50px 0 0;
        
        & b { font-weight:700; }
      }
      
      & .article {
        position:absolute;
        left:10%;
        bottom:calc(100% - 2px);
        pointer-events: none;
      }
    }
    
    @media (max-width:999px){
      padding:0;
      background:none;
      scroll-margin:83px 0 0;
      
      & .text-box {
        border-radius:20px;
        padding:50px 40px 60px;
        text-align:left;
        max-width:100%;
        
        & .title {
          font-size:24px;
          line-height:32.5px;
        }
        
        & .desc {
          margin:25px 0 0;
          font-size:14px;
          
          & b { font-weight:900; }
        }

        & .article {
          left:3%;
          & object {
            max-width:200px;
          }
        }
      }
    }
    
    @media (max-width:480px){
      & .text-box {
        & .desc {
          word-break: keep-all;
          & br { display:none; }
        }
      }
    }
  }
  
  &.content5 {
    position:relative;
    padding:205px 0 205px;
    border-radius:0;
    
    & .content-cover {
      width:100%;
      max-width:750px;
      margin:0 auto;
      
      & .text-box {
        text-align:center;
        font-size:45px;
        line-height:61px;
        
        & .bold { font-weight:900;}
        & b { font-weight:700; }
      }
      & .button-wrap {
        display:flex;
        align-items:center;
        justify-content: space-between;
        width:430px;
        margin:100px auto 0;
        font-weight:700;
        font-size:20px;
        line-height:29.6px;
        
        & [color="blue"], [color="white"] {
          position:relative;
          
          & a {
            transition: all .3s;
            background-color:#fff;
            
            & span { transition: all .3s; }
            &:hover { background-color:transparent; }
            &:hover span { color:#fff; }
          }
          &::after {
            content:'';
            display:block;
            position:absolute;
            top:0;
            left:0;
            width:100%;
            height:100%;
            background-image: linear-gradient(90deg, rgba(146,255,205,1) 0%, rgba(37,212,251,1) 100%);
            border-radius:25px;
            z-index:-1;
          }
        }
        & [color="white"] a { background-color:#000; }
          
          & .main-color {
            background-image: linear-gradient(90deg, rgba(146,255,205,1) 0%, rgba(37,212,251,1) 100%);
            background-clip: text;
            -webkit-background-clip: text;
            color:transparent;
            font-weight:700;
          }
      }
    }
    
    & .article1 {
      position:absolute;
      top:-30%;
      left:5%;
    }
    
    & .article2 {
      position:absolute;
      right:4%;
      bottom:10%;
    }
    
    @media (max-width:999px){
      padding:86px 0 93px;
      height:auto;
      
      & .content-cover {
        max-width:100%;
        padding:0 25px;
        
        & .text-box {
          font-size:25px;
          line-height:37px;
        }
        & .button-wrap {
          width:100%;
          flex-direction: column;
          margin:40px auto 0;
          gap:13px 0;
        }
      }
      
      & [class^="article"] { display:none;}
    }
  }
`

const Content = styled.div`
  & .content2 {
    padding: 180px 0 230px;

    & .text-box {
      text-align: left;

      & .title {
        font-size: 36px;
        font-weight: 900;
        color: #0185FF;
      }

      & .desc {
        font-size: 20px;
        margin: 36px 0 0;
      }
    }
    
    & .select-cover {
      position: relative;
      width:100%;
      max-width:950px;
      margin:0 auto;
      z-index: 99;

      & .find-select {
        width: 100%;
        max-width: 645px;
        margin: 65px 0 0 auto;
        padding: 60px 0 33px;
        border-radius: 50px;
        box-shadow: 5px 10px 9px 0 rgba(0, 0, 0, .3);
        background-image: linear-gradient(90deg, rgba(146, 255, 205, 1) 0%, rgba(37, 212, 251, 1) 100%);
        text-align: center;
        
        & .select-wrap{
          display:flex;
          justify-content: center;
          align-items: center;
          gap:0 11px;
          
          & .selected .react-select__control {
            background-color:#000;
          }

          & .react-select__single-value {
            background-image: linear-gradient(90deg, rgba(146,255,205,1) 0%, rgba(37,212,251,1) 100%);
            background-clip: text;
            -webkit-background-clip: text;
            color:transparent;
            font-weight:700;
          }
          
          & .react-select__placeholder {
            background-image: linear-gradient(90deg, rgba(146,255,205,1) 0%, rgba(37,212,251,1) 80%);
          }

          .react-select__control--is-focused,
          //.react-select__control:hover,
          .react-select__control:focus {
            background-color:#000;
            transition: all .6s;
          }
        }
        & .button-wrap{
          margin:15px 0 0;

          //& [color="blue"], [color="white"] {
          //  position:relative;
          //
          //  & a {
          //    transition: all .3s;
          //    background-color:#fff;
          //
          //    & span { transition: all .3s; }
          //    &:hover { background-color:transparent; }
          //    &:hover span { color:#fff; }
          //  }
          //  &::after {
          //    content:'';
          //    display:block;
          //    position:absolute;
          //    top:0;
          //    left:0;
          //    width:100%;
          //    height:100%;
          //    background-image: linear-gradient(90deg, rgba(146,255,205,1) 0%, rgba(37,212,251,1) 100%);
          //    border-radius:25px;
          //    z-index:-1;
          //  }
          //}
          //& [color="white"] a { background-color:#000; }
          //
          //& .main-color {
          //  background-image: linear-gradient(90deg, rgba(146,255,205,1) 0%, rgba(37,212,251,1) 100%);
          //  background-clip: text;
          //  -webkit-background-clip: text;
          //  color:transparent;
          //  font-weight:700;
          //}
          
          & a {
            font-size:20px;
            font-weight:700;
            line-height:30px;
          }
        }
      }
      
      & .article {
        position:absolute;
        bottom:65%;
        right:0;
      }
    }

    @media (max-width:999px){
      padding:103px 0 210px;
      & .text-box {
        max-width:100%;
        padding:0 25px;
        & .title {
          color:#000;
          line-height:53px;
          & .point { color:#0185FF; }
        }
        
        & .desc {
          font-size:14px;
          line-height:21px;
        }
      }
      
      & .select-cover {
        & .find-select {
          max-width:calc(100% - 30px);
          border-radius:20px 0 0 20px;
          margin:40px 0 0 auto;
          padding:30px 20px 14px;

          & .select-wrap{
            flex-direction: column;
            align-items: flex-start;
            gap:9px 0;
          }
          
          & .button-wrap {
            max-width:170px;
            margin:18px 0 0;
            
            & a {
              font-size:14px;
              font-weight:700;
            }
          }
        }
        & .article { bottom:30%; }
      }
      
    } // max-width: 999px
    
    @media (max-width:480px){
      & .select-cover {
        & .article {
          bottom:45%;
          right:-10%;
          & object {
            max-width: 200px;
          }
        }
      }
    }
  }
  
  &.content4 {
    padding:200px 0 355px;
    
    & .content-cover {
      width:100%;
      max-width:950px;
      margin:0 auto;

      & .content-box {
        display:flex;
        align-items:flex-start;
        justify-content: space-between;
        gap:0 90px;
        
        & .main-text {
          & .title {
            font-size:74px;
            font-weight:900;
          }
          & .desc {
            font-size:30px;
            font-weight:900;
          }
        }

        & .list {
          flex:1;
          & > ul > li {
            border:2px solid #000;
            border-radius:50px;
            padding:16px 40px 16px 70px;

            & .title {
              font-size:30px;
              font-weight:700;
            }

            & .desc {
              font-size:15px;
              font-weight:400;
              margin:15px 0 0;
            }
          }
        }
        & .list > ul > li + li { margin:13px 0 0; }
      }
      
      & .content-box + .content-box { margin:90px 0 0; }
    }
    
    @media (max-width:999px){
      padding:80px 10px 97px;
      
      & .content-cover {
        max-width:100%;
        
        & .content-box {
          flex-direction: column;
          align-items: center;
          
          & .main-text {
            text-align:center;
            
            & .title { font-size:48px; }
            & .desc { font-size:20px; }
          }
          
          & .list {
            margin:24px 0 0;
            flex:none;
            width:100%;
            
            & > ul > li {
              display:flex;
              flex-direction: column;
              justify-content: center;
              border:2px solid #000;
              border-radius:50px;
              padding:18px 12px;
              text-align:center;
              min-height:97px;

              & .title {
                font-size:20px;
              }

              & .desc {
                font-size:14px;
                margin:5px 0 0;
                line-height:18px;
              }
            }
          }
        }
        & .content-box + .content-box { margin:60px 0 0; }
      }
    }
    
    @media (max-width:480px){
      & .content-cover {
        & .content-box {
          & .main-text {
            & .title {}
            & .desc {}
          }

          & .list {
            & > ul > li {
              & .title { font-size:20px; }
              & .desc { font-size:13px; }
            }
          }
        }
      }
    }
  }
`

export default Index;