import React, {useEffect, useRef, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import SelectBox from "../common/SelectBox";
import RoundButton from "../common/RoundButton";

import SearchIcon from "../../assets/images/search-mo.svg";
import axios from "axios";
import {useDispatch, useSelector} from "react-redux";
import {getSearchValue, getSearchAreas, getSearchCount, getSearchPage} from "../../store";

const SelectLocation = ({
        bgColor,
        color,
        size,
        link,
        onClick,
        className,
        text,
        isMobile,
        searchAreas1,
        searchAreas2,
    }) => {
    const navigate = useNavigate();
    let dispatch = useDispatch();
    const API_KEY = process.env.REACT_APP_API_KEY;

    let searchMain = searchAreas1 ? searchAreas1 : "";
    let searchSub = searchAreas2 ? searchAreas2 : "";

    let [optionValue1, setOptionValue1] = useState(searchMain);
    let [mainArea, setMainArea] = useState([]);
    let [mainAreaId, setMainAreaId] = useState("");

    let [optionValue2, setOptionValue2] = useState(searchSub)
    let [subArea, setSubArea] = useState([]);
    let [subAreaId, setSubAreaId] = useState("");

    useEffect(()=>{
        axios.get(`${API_KEY}/static/areas`).then((res)=>{
            let result = res.data.data;
            let mainArea = result.filter((x)=> x.parent_category === "");
            let subArea = result.filter((x)=> x.parent_category !== "");

            for( const i in mainArea ){
                mainArea[i].value = mainArea[i]["id"];
                mainArea[i].label = mainArea[i]["area_name"];
            }

            for( const i in subArea ){
                subArea[i].value = subArea[i]["id"];
                subArea[i].label = subArea[i]["area_name"];
            }

            setMainArea(mainArea);
            setSubArea(subArea);
        }).catch((err)=>{
            console.log(err)
        })
    },[])

    useEffect(()=>{
        searchAreaFunc()
    },[searchAreas1, searchAreas2])
    const locationSelect = () => {
        if( optionValue1 === "" ){
            return alert("시/도를 선택해주세요.");
        }
        else if ( optionValue2 === "" ){
            return alert("구/군을 선택해주세요.");
        }
        else if( optionValue1 !== "" && optionValue2 !== "" ){
            searchAreaFunc()
        }
    }
    const searchAreaFunc = () => {
        axios.get(`${API_KEY}/search?areaId=${optionValue2}&page=1`).then((res)=>{
            let result = {
                data: res.data.data,
                status: "init"
            };
            let areas = {
                areaOption1: res.data.selected_area.parent_category,
                areaOption2: res.data.selected_area.area_name,
                areaId1 : res.data.selected_area.parentAreaId,
                areaId2 : res.data.selected_area.id,
            }

            dispatch(getSearchValue(result));
            dispatch(getSearchAreas(areas));
            dispatch(getSearchCount(res.data.count));
            dispatch(getSearchPage(res.data.page));
            navigate('/views/list');
        }).catch((err)=>{
            console.log(err)
        })
    }
    return(
        <div className={"select-location"}>
            <div className={"select-wrap"}>
                <SelectBox
                    option={mainArea}
                    placeholder={"시/도 선택"}
                    onChange={(e)=>{
                        setOptionValue1(e.value)
                        setOptionValue2("")
                    }}
                    className={optionValue1 !== "" && "selected"}
                    value={mainArea.filter((x)=> x.value === optionValue1)}
                />
                <SelectBox
                    option={subArea.filter((x)=> x.parentAreaId === optionValue1)}
                    noOptionsMessage={"시/도를 선택해주세요."}
                    placeholder={"구/군 선택"}
                    onChange={(e)=>{setOptionValue2(e.value)}}
                    className={optionValue2 !== "" && "selected"}
                    value={subArea.filter((x)=> x.value === optionValue2)}
                />
            </div>
            <div className={"button-wrap"}>
                <RoundButton
                    bgColor={bgColor}
                    color={color}
                    size={size}
                    className={className}
                    onClick={locationSelect}
                >{className === "search" && isMobile === true ? <img src={SearchIcon}/> : text}</RoundButton>
            </div>
        </div>
    );
}

export default SelectLocation;
