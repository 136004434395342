import React, {useEffect, useState} from 'react';
import { useLocation } from 'react-router-dom';
import styled from "styled-components";
import { Link } from "react-router-dom";
import RoundButton from "../components/common/RoundButton";

import BgColor2 from "../assets/images/bgColor2.png";
import Profile from "../assets/images/profile.jpg";
import Card from "../assets/images/card.svg";
import NoImageCard from "../assets/images/no-image-card.svg";
import NoImageProfile from "../assets/images/no-image-profile.svg";
import CloseBtn from "../assets/images/close-btn.svg";
import SearchIcon from "../assets/images/search-mo.svg";

import SelectBox from "../components/common/SelectBox";
import {useMediaQuery} from "react-responsive";
import SelectLocation from "../components/layout/SelectLocation";
import { bankLogo } from "../components/common/bankLogo";
import {useDispatch, useSelector} from "react-redux";
import {getSearchValue} from "../store";
import axios from "axios";
import userEvent from "@testing-library/user-event";

const ListCounselor = () => {
    const API_KEY = process.env.REACT_APP_API_KEY;
    const location = useLocation();

    let searchResult = useSelector((state)=> { return state.searchResult });
    let searchAreas = useSelector((state)=> { return state.searchAreas });
    let searchCount = useSelector((state)=> { return state.searchCount });
    let searchPage = useSelector((state)=> { return state.searchPage });
    let dispatch = useDispatch();
    let locationOption1 = searchAreas.areaOption1;
    let locationOption2 = searchAreas.areaOption2;

    // 모바일인지 체크
    let isMobile = useMediaQuery({
        query : "(max-width:999px)",
    });

    let [userInfo, setUserInfo] = useState(false); // 팝업 오픈
    let [userData, setUserData] = useState({}); // 상담사 정보
    let [prdData, setPrdData] = useState([]); // 취급 상품 목록
    let [associationsData, setAssociationsData] = useState({}); // 금융협회 정보
    let [associationName, setAssociationName] = useState(""); // 금융협회 이름

    const addViews = () => {
        let searchLength = searchResult.length;
        let pageCount = 1 + searchLength / 15;
        if( pageCount % 1 === 0 ){
            axios.get(`${API_KEY}/search?areaId=${searchAreas.areaId2}&page=${pageCount}`).then((res)=>{
                let result = {
                    data: res.data.data,
                    status: "add"
                }
                dispatch(getSearchValue(result));
            }).catch((err)=>{
                console.log(err)
            })
        }
    }

    const isSelected = (user) => {
        setUserInfo(true);
        setAssociationName(user.association_name.split(' ')[0]);
        axios.get(`${API_KEY}/static/associations?name=${user.association_name}`).then((res)=>{
            setAssociationsData(res.data.data)
        }).catch((err)=> {
            console.log(err)
        })

        // 클릭 수 증가 / 반영 -> 어드민 페이지 미반영
        axios.get(`${API_KEY}/users/click?id=${user.id}`).catch((err)=> {
            console.log(err)
        })

        setPrdData(userProductList(user))
        setUserData(user); // 유저 데이터 전달
    }

    const userProductList = (user) => {
        let mainList = [];
        let middleList = [];
        let subList = [];
        let prdValues = [];
        let list = [];

        // Categorize products into main, middle, and sub lists
        user.UserProducts.forEach((x) => {
            if (x.Product.category_type === "소분류") {
                subList.push(x.Product);
            } else if (x.Product.category_type === "중분류") {
                middleList.push(x.Product);
            } else if (x.Product.category_type === "대분류") {
                mainList.push(x.Product);
            }
        });

        // Build the hierarchical structure
        mainList.forEach((main_item) => {
            let main = Object.assign({}, main_item);

            main.middle = [];

            if (main.has_middle_product) {
                middleList.forEach((middle_item) => {
                    let middle = Object.assign({}, middle_item);
                    middle.sub = [];
                    if (middle.parentProductId === main.id) {
                        main.middle.push(middle);
                    }

                    subList.forEach((sub) => {
                        if (sub.parentProductId === middle.id) {
                            middle.sub.push(sub);
                        }
                    });
                });
            } else {
                subList.forEach((sub) => {
                    if (sub.parentProductId === main.id) {
                        main.middle.push(sub);
                    }
                });
            }

            prdValues.push(main);
        });

        // Create the final list of product hierarchies
        prdValues.forEach((prd) => {
            if (prd.middle && prd.middle.length > 0) {
                if (prd.has_middle_product) {
                    prd.middle.forEach((middle) => {
                        if (middle.sub && middle.sub.length > 0) {
                            list.push(prd.product_name + " > " + middle.product_name + " > " + middle.sub.map(sub => sub.product_name).join(", "));
                        } else {
                            list.push(prd.product_name + " > " + middle.product_name);
                        }
                    });
                } else {
                    list.push(prd.product_name + " > " + prd.middle.map(middle => middle.product_name).join(", "));
                }
            } else {
                list.push(prd.product_name);
            }
        });

        return list;
    }

    return (
        <ListCounselorWrap className={`${searchResult.length === 0 ? "no-data" : ""}`}>
            <div className={"select-container"}>
                <SelectLocation
                    bgColor={"black"}
                    size={"mini"}
                    color={"white"}
                    className={"search"}
                    text={"검색"}
                    isMobile={isMobile}
                    searchAreas1={searchAreas?.areaId1}
                    searchAreas2={searchAreas?.areaId2}
                />
            </div>
            <div className={`list-wrap`}>
                {searchResult.length !== 0 ? (
                        <>
                            <h1 className={"title"}>
                                {locationOption1} {locationOption2}의<br/>
                                대출 전문 상담사
                            </h1>
                            <div className={"list-box"}>
                                {
                                    searchResult.map((x, i) => {
                                        return (
                                            <div
                                                className={"item"}
                                                onClick={() => isSelected(x)}
                                                key={i}
                                            >
                                                <div className={"images"}>
                                                    <div className={"profile"}>
                                                        <img
                                                            src={x.Image?.photo_path && x.Image?.photo_path != 'null' ? `${process.env.REACT_APP_API_KEY}/${JSON.parse(x.Image?.photo_path).split('public/')[1]}` : NoImageProfile}/>
                                                        <div className={"logo"}>
                                                            {
                                                                bankLogo[x.financial_company] ?
                                                                    <LogoImg src={bankLogo[x.financial_company]}
                                                                             alt={"Logo"}/> :
                                                                    x.financial_company.slice(0, 2)
                                                            }
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className={"info"}>
                                                    <div className={"name"}>{x.name}</div>
                                                    <div className={"desc"}>
                                                        {
                                                            userProductList(x).map((item, index) =>{
                                                                if(index > 1) return;
                                                                return (
                                                                    <div className={"sort-item"} key={index}>{item}</div>
                                                                )
                                                            }
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                        </>) :
                    (<h1 className={"title"}>
                        대출 전문 상담사<br/>
                        조회 결과가 없습니다.
                    </h1>)}
                    {
                        // 더 남은 데이터가 없을경우도 버튼 없애야함
                        searchPage === 0 ? null :
                        searchCount <= searchResult.length ? null :
                            <div className={"button-wrap"}>
                                <RoundButton
                                    size={"default"}
                                    bgColor={"black"}
                                    color={"white"}
                                    onClick={addViews}
                                ><span>+ 더보기</span></RoundButton>
                            </div>
                    }
                </div>
            <ListModal className={`list-modal ${userInfo ? "on" : ""}`}>
                <div
                    className={"dimmed"}
                    onClick={() => setUserInfo(false)}
                ></div>
                <div className={"pop-cover"}>
                    <div className={"content-wrap"}>
                        <div className={"top-content"}>
                            <div
                                className={"close-btn"}
                                onClick={()=> setUserInfo(false)}
                            ><img src={CloseBtn}/></div>
                            <div className={"card-image"}>
                                {
                                    userData.Image?.businesscard_path && userData.Image?.businesscard_path != 'null' ?
                                        <img src={`${process.env.REACT_APP_API_KEY}/${JSON.parse(userData.Image?.businesscard_path).split('public/')[1]}`} /> :
                                            <div className={"no-image"}><object data={`${NoImageCard}`}/></div>
                                }
                            </div>
                        </div>
                        <div className={"bot-content"}>
                            <div className={"content-cover"}>
                                <div className={"profile"}>
                                    <div className={"left-area"}>
                                        <div className={"introduce"}>
                                            안녕하세요.<br/>
                                            <span className={"color"}>{userData.name}</span> 상담사입니다.
                                        </div>
                                        <div className={"time"}>
                                            <h3 className={"title"}>상담시간</h3>
                                            <div className={"desc color"}>
                                                {userData?.UserWeeks?.map((x, i) => {return x.Week?.name}).join(", ")} | {userData?.Consulting?.time.join(" - ")}
                                            </div>
                                        </div>
                                        <div className={"phone"}>
                                            <h3 className={"title"}>상담전화</h3>
                                            <div className={"desc"}><Link className={"color"} to={`tel:${userData?.Consulting?.tel}`}>{userData.Consulting?.tel}</Link></div>
                                        </div>
                                    </div>
                                    <div className={"right-area"}>
                                        <div className={"profile-img"}>
                                            {
                                                userData?.Image?.photo_path && userData?.Image?.photo_path != 'null' ?
                                                <img src={`${process.env.REACT_APP_API_KEY}/${JSON.parse(userData.Image?.photo_path).split('public/')[1]}`}/> :
                                                <div className={"no-image"}><object data={`${NoImageProfile}`}/></div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div className={"product"}>
                                    <h3 className={"title"}>취급상품</h3>
                                    <div className={"desc"}>
                                        <ul className={"product-list"}>
                                            {prdData.map((x, i) => (
                                                <li key={i}>{x}</li>
                                            ))}
                                        </ul>
                                    </div>
                                </div>
                                <div className={"financial-num"}>
                                    <h3 className={"title"}>금융협회 등록번호</h3>
                                    <div className={"desc"}>
                                        <div
                                            className={"number"}
                                            onClick={(e)=>{
                                                let text = "";
                                                if(userData.association_name === "은행연합회") text = e.target.innerText.split("-")[1];
                                                else text = e.target.innerText;
                                                navigator.clipboard.writeText(text);
                                                alert("금융협회 등록번호가 복사되었습니다.");
                                                window.open(associationsData.link);
                                            }}
                                        >{userData.association_code}</div>
                                        <div className={"site"}><Link to={associationsData.link} target={"_blank"}>{associationsData.name}</Link></div>
                                        <div className={"bank"}>{userData.Consulting?.company_name} | <Link to={`tel:${userData.Consulting?.company_tel}`}>{userData.Consulting?.company_tel}</Link></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"pop-foot"}>
                            <div className={"desc"}>
                                {

                                    userData?.Consulting?.type === "금융사" ||
                                    userData?.Consulting?.type === "금융사 소속 대출모집인" ?
                                    <>위 사람은 {userData.financial_company} 소속 {associationName}에 등록된 대출 상담사입니다.</> :
                                    <>위 사람은 {userData.financial_company}과(와) 위탁계약 체결하고 {associationName}에 등록된 대출상담사입니다.</>
                                }
                            </div>
                            <div className={"notice"}>"대출중개수수료는 불법이며, 별도의 수수료를 요구하거나 수취할 수 없습니다."</div>
                        </div>
                    </div>
                </div>
            </ListModal>
        </ListCounselorWrap>
    )
}

const ListCounselorWrap = styled.div`
  position:relative;
  background:url(${BgColor2}) no-repeat center/cover;
  padding:252px 0 0;
  
  &.no-data {
    padding:252px 0 100px;
    & .list-wrap {
      padding:46px 94px 150px;
      border-radius: 50px;
    }
  }
  
  & .select-container {
    display:flex;
    justify-content: flex-end;
    align-items: center;
    max-width:1120px;
    padding:0 100px 0 0;
    margin:0 auto;
    
    & .select-location {
      display:flex;
      align-items:center;
      & .select-wrap {
        display:flex;
        align-items:center;

        & > [class$='-container'] + [class$='-container'] { margin:0 0 0 8px; }
      }
    }

    & .selected .react-select__control {
      background-color:#000;
    }
    
    & .search { 
      margin:0 0 0 13px;
      
      & a:hover {
        border:2px solid;
        color:#000;
        background-color:#fff;
        font-weight:500;
      }
    }
  }
  
  & .list-wrap {
    max-width:1120px;
    margin:25px auto 0;
    border-radius:50px 50px 0 0;
    background-color:rgba(255,255,255,0.8);
    padding:46px 94px 90px;
    
    & .title {
      font-size:36px;
      font-weight:700;
      line-height:53px;
    }
    
    & .list-box {
      //display:flex;
      //flex-wrap:wrap;
      //gap:25px;
      display:grid;
      grid-template-columns:repeat(auto-fill,minmax(calc(33% - 25px), 1fr));
      gap:25px;
      margin:32px 0 0;
      
      & .item {
        //min-width:294px;
        //max-width:294px;
        border-radius:5px;
        background-color:#fff;
        box-shadow:0 4px 4px 0 rgba(0,0,0,.3);
        padding:36px 34px 23px;
        cursor:pointer;
        border:5px solid transparent;
        box-sizing: content-box;
        
        & .images {
          display:flex;
          justify-content: flex-end;
          
          & .profile {
            position:relative;
            max-width:135px;
            min-width: 135px;
            & img {
              display:block;
              width:100%;
              height:182px;
              max-height:182px;
              object-fit: cover;
            }

            & .logo {
              display:flex;
              justify-content: center;
              align-items: center;
              background-color:#fff;
              position:absolute;
              top:-20px;
              left:-32px;
              width:62px;
              height:62px;
              border-radius:50%;
              overflow:hidden;
              box-shadow:0 4px 4px 0 rgba(0,0,0,.3);
            }
          }
        }
        
        &:hover {
          border:5px solid #0185FF;
        }
      }
      
      & .info {
        & .name {
          margin:18px 0 0;
          font-size:25px;
          font-weight:900;
          line-height:37px;
        }
        
        & .desc {
          margin:4px 0 0;
          font-size:14px;
          line-height:20px;
          
          & .sort-item {
            white-space:nowrap;
            text-overflow:ellipsis;
            overflow:hidden;
          }
        }
      }
    }
    
    & .button-wrap {
      text-align:center;
      margin:60px 0 0;
      
      & [color="white"]{
        position:relative;
        display:inline-block;
        & a {
          position:relative;
          width:300px;
          height:70px;
          font-size:20px;
          line-height:30px;
          font-weight:400;
          border-radius:100px;
          transition:all .3s;
          background-color:#000;
          z-index:1;

          & span { color:#fff; transition: all .3s; }
          &:hover { background-color:transparent; }
          &:hover span { color:#000; }
        }
        &::after {
          content:'';
          display:block;
          position:absolute;
          top:0;
          left:0;
          width:100%;
          height:100%;
          background-image: linear-gradient(90deg, rgba(146,255,205,1) 0%, rgba(37,212,251,1) 100%);
          border-radius:100px;
        }
      }
    }
  }
  
  &::after {
    content:'';
    display:block;
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:184px;
    background:linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%);
  }
  
  @media (max-width:999px){
    padding:110px 0 0;
    
    &.no-data {
      padding:110px 20px 80px;
      & .list-wrap {
        padding:46px 46px 100px;
        border-radius:50px;
        background:rgba(255,255,255,0.8);
      }
    }
    
    & .select-container {
      justify-content: center;
      max-width:100%;
      padding:0;
      
      & [class$="-container"] {
        width:130px;
        & .react-select__control, .react-select__menu { width:130px; }
      }
      
      & .search {
        margin:0 0 0 6px; 
        & a {
          background: transparent;
          width: auto;
          
          &:hover {
            border:0;
          }
        }
      }
      & >[class$='-container']+[class$='-container'] { margin:0 0 0 4px; }
    }
    
    & .list-wrap {
      max-width:100%;
      margin:22px 0 0;
      padding:0 12px 60px;
      border-radius: 0;
      background: none;
      
      & .title {
        font-size:22px;
        line-height:28px;
      }
      
      & .list-box {
        display:flex;
        flex-direction: column;
        margin:21px 0 0;
        
        & .item {
          flex:1;
          max-width:100%;
          display:flex;
          align-items: flex-end;
          justify-content: space-between;
          gap:0 20px;
          padding: 22px 30px 22px 23px;
          
          & .images {
            flex:1 1 auto;
            order:2;
            
            & .profile > img {
              min-width: 135px;
              max-width:135px;
              min-height:168px;
              max-height:168px;
            }
          }
          
          & .info {
            min-width:0;
            flex:1 1 auto;
            order:1;
            overflow:hidden;
            & .name { 
              margin:0;
              overflow:hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
    
    & button {
      padding:0;
      border:0;
      cursor:pointer;
      & img {
        display:block;
        width:100%;
        height:auto;
      }
    }
    
    &::after { display:none; }
  }
  
`

const ListModal = styled.div`
  display:none;
  position:fixed;
  top:0;
  left:0;
  width:100%;
  height:100%;
  z-index:199;
  
  &.on { display:block; }
  
  & .dimmed {
    position:absolute;
    top:0;
    left:0;
    width:100%;
    height:100%;
    background-color:rgba(0,0,0,.6);
  }
  
  & .pop-cover {
    position:absolute;
    top:50%;
    left:50%;
    transform:translate(-50%, -50%);
    max-width:700px;
    width:100%;
    border-radius:15px;
    overflow:hidden;
    
    & .content-wrap {
      background-color:#fff;
      height:calc(80vh + 1px);
      overflow-y: scroll;
      border-radius:15px;
    }
    
    
    & .top-content {
      padding:76px 0 30px;
      background-color:#fff;
      
      & .close-btn {
        position:absolute;
        top:12px;
        left:50%;
        transform:translate(-50%, 0);
        display:flex;
        justify-content: center;
        align-items: center;
        width:39px;
        height:39px;
        cursor:pointer;
        background-color:rgba(205,202,202,.3);
        transition: all .3s;
        border-radius:50%;
        
        &:hover { background-color:#000; }
        &:hover:after {
          content:'닫기';
          display:block;
          position:absolute;
          top:calc(100% + 5px);
          left:0;
          width:100%;
          text-align:center;
          font-size:12px;
        }
      }
      
      & .card-image {
        display:flex;
        justify-content: center;
        align-items: center;
        
        & .no-image {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        & img, object {
          min-width:410px;
          min-height:226px;
          max-width:410px;
          max-height:226px;
          object-fit: cover;
        }
      }
    }
    
    & .bot-content {
      border-top:1px solid transparent;
      background-image:linear-gradient(#fff, #fff), linear-gradient(to right, rgba(146,255,205,1) 0%, rgba(37,212,251,1) 100%);
      background-origin: border-box;
      background-clip: content-box, border-box;
      border-image-slice:1;
      border-radius:10px 10px 0 0;
      padding:1px 0 0;
      overflow:hidden;
      
      & .content-cover {
        padding:33px 30px 0;
      }
      
      & .profile {
        display:flex;
        justify-content: space-between;
        align-items:center;
        padding-bottom:10px;
        
        & .left-area {
          & .introduce {
            font-size:20px;
            font-weight:700;
            line-height:25px;
          }
          
          & .time { margin:20px 0 0; }
          & .phone { margin:7px 0 0; }
          
          & .title {
            font-size:14px;
            font-weight:900;
            line-height:26px;
          }
          & .desc {
            font-size:14px;
            font-weight:500;
          }
        }
        
        & .right-area {
          & .profile-img {
            & .no-image {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            & :not(.no-image) object, img{
              display:block;
              width:100%;
              height:169px;
              max-width:135px;
              max-height:169px;
              object-fit: cover;
            }
          }
        }
      }
      
      & .product, .financial-num {
        display:flex;
        gap:0 15px;
        border-top:1px solid #CDCACA;
        
        & .title {
          font-size:14px;
          font-weight:900;
          line-height:21px;
          min-width:66px;
        }
      }

      & .product { padding:22px 0 33px; }
      & .product .product-list > li {
        font-size:14px;
        font-weight:400;
        line-height:22px;
      }
      & .product .product-list > li + li { margin:11px 0 0; }

      & .financial-num { padding:25px 0 20px; }
      & .financial-num .desc {
        display:flex;
        flex-direction: column;
        align-items: flex-start;
        & .number { text-decoration: underline; }
        & .number, .site {
          position:relative;
          cursor:pointer;
        }
      }
      & .financial-num .desc > div { 
        display:inline-block;
        color:#0185FF;
        font-size:14px;
        font-weight:500;
        line-height:22px;
        & a { text-decoration: underline; color:#0185FF; word-break: break-all }
      }
      & .financial-num .desc > div + div { margin:2px 0 0; }
    }
  }
  
  & .pop-foot {
    background-color:#000;
    text-align:center;
    padding:12px 0;
    border-bottom:1px solid;
    
    & .desc {
      font-size:12px;
      font-weight:300;
      line-height:22px;
      color:#fff;
    }
    
    & .notice {
      font-size:14px;
      font-weight:500;
      line-height:22px;
      color:#92FFCD;
    }
  }
  
  & .color { color:#0185FF; }
  
  @media (max-width:999px){
    & .dimmed { display:none; }

    & .pop-cover {
      height:100%;
      max-width:100%;
      border-radius:0;
      & .content-wrap {
        top:0;
        left:0;
        transform:none;
        background-color:#fff;
        height:100%;
        overflow-y: scroll;
        border-radius:0;
        overflow-y:auto;
        max-width:100%;
      }

      & .top-content {
        padding:0;
        & .close-btn {
          position:fixed;
          bottom:20px;
          left:50%;
          top:auto;
          transform:translate(-50%, 0);
          z-index:99;
        }
        
        & .card-image {
          margin:0;
          *overflow:hidden;
          & img {
            display:block;
            width:100%;
            height:auto;
          }
        }
      }
      
      & .bot-content {
        position:relative;
        top:-5px;
        z-index:10;
        & .content-cover {
          padding:43px 20px 22px 22px;
          
          & .profile {
            align-items:flex-start;
            padding-bottom:12px;
            
            & .left-area {
              & .time { margin:9px 0 0; }
              & .phone { margin:4px 0 0; }
            }
            
            & .right-area {
              img {
                max-width:80px;
                height:110px;
                max-height:110px;
              }
            }
          }
          
          & .product {
            padding:15px 0;
            & .product-list > li + li { margin:3px 0 0; }
          }
          
          & .financial-num { 
            padding:25px 0 0;
          }
        }
      }
      
      & .pop-foot {
        padding:20px 22px 40px 22px;
        text-align:left;
        
        & .notice { margin:20px 0 0; }
      }
    }
  }
  
  @media (max-width:420px) {
    & .pop-cover {
      & .top-content {
        & .card-image {
          img {
            min-width: 0;
            max-width: none;
          }
        }
      }
    }
  }
`

const LogoImg = styled.img`
    width: 50px !important;
    height: auto !important;
`;

export default ListCounselor;
