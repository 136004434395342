import React, {useState} from 'react';
import styled from "styled-components";

import Policy from "../layout/Policy";
import FootLogo from "../../assets/images/logo-foot.svg"
import {Link} from "react-router-dom";
import Privacy from "./Privacy";

const Footer = ()=>{
    let [openPolicy, setOpenPolicy] = useState(false);
    let [openPrivacy, setOpenPrivacy] = useState(false);
    return(
        <FooterContainer>
            <div className={"top-content"}>
                <div className={"logo"}>
                    <div className={"img"}><Link to={"/"}><object data={FootLogo}/></Link></div>
                    <div className={"copyright"}>(C) loanandtalk. ALL RIGHTS RESERVED.</div>
                </div>
                <div className={"foot-menu"}>
                    <div className={"left-menu"}>
                        <h3 className={"title"}>상담사 등록번호 조회</h3>
                        <ul className={"list"}>
                            <li><Link to={"https://www.loanconsultant.or.kr/loan_main.do"} target={"_blank"}>은행 / 저축은행 / 보험사 / 캐피탈  상담사 조회</Link></li>
                            <li><Link to={"https://www.standardchartered.co.kr/np/kr/pl/wb/VisitbankingSearch.jsp"} target={"_blank"}>제일은행 상담사 조회</Link></li>
                            <li><Link to={"https://banking.nonghyup.com/servlet/IPEL0381I.view?saac=saac"} target={"_blank"}>농협 대출모집인 조회</Link></li>
                            <li><Link to={"https://www.kfcc.co.kr/customer/cus1104_03.do"} target={"_blank"}>새마을금고 대출모집인 조회</Link></li>
                        </ul>
                    </div>
                    <div className={"right-menu"}>
                        <h3 className={"title"}>문의하기</h3>
                        <ul className={"list"}>
                            <li><Link to={"https://docs.google.com/forms/d/e/1FAIpQLSfr0C5ZI8h3Qdo1C54Ra8hDczlz6Yif1l8-hjX7Bu2VTcN8zw/viewform"} target={"_blank"}>상담사등록 신청</Link></li>
                            <li><Link to={"/views/support"}>일반문의</Link></li>
                            <li><Link to={"/views/support"}>협업문의</Link></li>
                        </ul>
                    </div>
                </div>
            </div>
            <div className={"bot-content"}>
                <div className={"notice"}>
                    <div onClick={()=>{setOpenPolicy(true)}}>이용약관</div>
                    <div
                        className={"line main-color"}
                        onClick={()=>{setOpenPrivacy(true)}}
                    >개인정보처리방침</div>
                </div>
                <div className={"company-info"}>
                    <span>아파트금융톡 주식회사</span>
                    <span className={"line"}>서울특별시 강동구 천호옛길 88, A572호 (성내동, 지라이트빌딩)</span><br/>
                    <span>대표자 : 김진수</span>
                    <span className={"line"}>사업자등록번호 814-87-02830</span>
                </div>
                <div className={"page-info"}>
                    론N톡은 ‘아파트금융톡’에서 건강하고 깨끗한 대출생활을 위하여 상담사 정보를 제공하는 사이트입니다.
                    당사는 찾기 어려운 대출상담사 정보*만 제공할 뿐, 금융사별 대출 정보 및 상품 추천은 하지 않습니다.
                    문의고객, 상담사의 상담내용 및 선택에 관여하지 않습니다.
                    본 사이트에 등록된 상담사와 상담고객간의 상담 내용에 대해 어떠한 법적책임도 부담하지 않습니다.
                    론N톡에 표시된 대출 상담사 정보는 해당 상담사가 직접 제공한 것이며,
                    무단으로 복제, 편집, 전시, 전송, 배포, 판매, 방송, 공연 등에 이용할 수 없습니다.
                </div>
            </div>
            <Policy openPolicy={openPolicy} setOpenPolicy={setOpenPolicy} />
            <Privacy openPrivacy={openPrivacy} setOpenPrivacy={setOpenPrivacy} />
            {/*<ModalPopup className={"privacy"}></ModalPopup>*/}
        </FooterContainer>
    )
}

const FooterContainer = styled.div`
  display:flex;
  flex-direction: column;
  justify-content: space-between;
  background-color:#1f1f1f;
  color:#fff;
  padding:20px;
  min-height:550px;
  
  & .logo {
    & .copyright {
      font-size:13px;
      margin:10px 0 0;
    }
  }
  
  & .foot-menu {
    display:flex;
    gap:0 40px;
    margin:20px 0 0;
    
    & .title {
      font-size:14px;
      font-weight:900;
      margin-bottom:10px;
    }
    & .list > li > a {
      color:#fff;
      font-size:13px;
    }
    & .list > li + li { margin:5px 0 0; }
  }
  
  & .notice {
    display:flex;
    align-items: center;
    gap:0 10px;
    
    & div {
      font-size:14px;
      font-weight:500;
      color:#fff;
      cursor:pointer;
      
      &.main-color {
        background-image: linear-gradient(90deg, rgba(146,255,205,1) 0%, rgba(37,212,251,1) 100%);
        background-clip: text;
        -webkit-background-clip: text;
        color:transparent;
        font-weight:900;
      }
      
      &.line:after {
        top:1px;
        height:13px;
      }
    }
  }
  
  & .company-info {
    margin:20px 0 0;
    font-size:13px;
    line-height:1.4;
    
    & .line {
      margin-left:10px;
    }
  }
  
  & .page-info {
    margin:20px 0 0;
    font-size:13px;
    max-width:800px;
    line-height:1.3;
  }
  
  & .line {
    position:relative;
    &::after {
      content:'';
      display:block;
      position:absolute;
      top:4px;
      left:-5px;
      width:1px;
      height:11px;
      background-color:#fff;
    }
  }
  
  @media (max-width:999px){
    padding:17px 14px 35px 21px;
    min-height:auto;
    
    & .top-content {
      & .foot-menu {
        & .title {
          font-size:13px;
        }
        & .list > li > a {
          font-size:12px;
        }
      }
    }
    
    & .bot-content {
      margin:80px 0 0;
      & .notice {
        & > div { font-size:13px; }
        & a { font-size: 13px; }
      }
      & .company-info, .page-info { font-size:12px; }
    }
  }
  
  @media (max-width:480px){
    & .top-content {
      & .logo {
        & .copyright { font-size:12px; }
      }
      & .foot-menu {
        & .title {
          font-size:12px;
        }
        & .list > li > a {
          font-size:11px;
        }
      }
    }

    & .bot-content {
      & .notice {
        & > div { font-size:12px; }
        & a { font-size: 12px; }
      }
      & .company-info, .page-info { font-size:11px; }
    }
  }
`


export default Footer;