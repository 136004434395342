import kebhanaBankLogo from "../../assets/images/logo/kebhana.jpg";
import scBankLogo from "../../assets/images/logo/standardchartered.jpg";
import kbstarBankLogo from "../../assets/images/logo/kbstar.jpg";
import shinhanBankLogo from "../../assets/images/logo/shinhan.jpg";
import wooriBankLogo from "../../assets/images/logo/wooribank.jpg";
import citiBankLogo from "../../assets/images/logo/citibank.jpg";
import ibkBankLogo from "../../assets/images/logo/ibk.jpg";
import kdbBankLogo from "../../assets/images/logo/kdbBank.jpg";
import koreaeximLogo from "../../assets/images/logo/koreaexim.jpg";
import knbankLogo from "../../assets/images/logo/knbank.jpg";
import kjbankLogo from "../../assets/images/logo/kjbank.jpg";
import busanbankLogo from "../../assets/images/logo/busanbank.jpg";
import jbbankLogo from "../../assets/images/logo/jbbank.jpg";
import jejubankLogo from "../../assets/images/logo/jejubank.jpg";
import nhBankLogo from "../../assets/images/logo/nhbank.jpg";
import suhyupBankLogo from "../../assets/images/logo/suhyup-bank.jpg";
import mgBankLogo from "../../assets/images/logo/kfcc.jpg";
import abllifeLogo from "../../assets/images/logo/abllife.jpg";
import idblifeLogo from "../../assets/images/logo/idblife.jpg";
import dgbfnlifeLogo from "../../assets/images/logo/dgbfnlife.jpg";
import orangelifeLogo from "../../assets/images/logo/orangelife.jpg";
import kdblifeLogo from "../../assets/images/logo/kdblife.jpg";
import pcakoreaLogo from "../../assets/images/logo/pcakorea.jpg";
import kyoboLogo from "../../assets/images/logo/kyobo.jpg";
import myangelLogo from "../../assets/images/logo/myangel.jpg";
import metlifeLogo from "../../assets/images/logo/metlife.jpg";
import miraeassetLogo from "../../assets/images/logo/miraeasset.jpg";
import samsunglifeLogo from "../../assets/images/logo/samsunglife.jpg";
import shinhanlifeLogo from "../../assets/images/logo/shinhanlife.jpg";
import chubblifeLogo from "../../assets/images/logo/chubblife.jpg";
import prudentialLogo from "../../assets/images/logo/prudential.jpg";
import hanwhalifeLogo from "../../assets/images/logo/hanwhalife.jpg";
import heungkuklifeLogo from "../../assets/images/logo/heungkuklife.jpg";
import directdbLogo from "../../assets/images/logo/directdb.jpg";
import kbinsureLogo from "../../assets/images/logo/kbinsure.jpg";
import mggeneralinsLogo from "../../assets/images/logo/mggeneralins.jpg";
import sgicLogo from "../../assets/images/logo/sgic.jpg";
import lotteinsLogo from "../../assets/images/logo/lotteins.jpg";
import meritzfireLogo from "../../assets/images/logo/meritzfire.jpg";
import samsungfireLogo from "../../assets/images/logo/samsungfire.jpg";
import hwgeneralinsLogo from "../../assets/images/logo/hwgeneralins.jpg";
import hiLogo from "../../assets/images/logo/hi.jpg";
import heungkukfireLogo from "../../assets/images/logo/heungkukfire.jpg";
import woorifcapitalLogo from "../../assets/images/logo/woorifcapital.jpg";
import bccardLogo from "../../assets/images/logo/bccard.jpg";
import kbcapitalLogo from "../../assets/images/logo/kbcapital.jpg";
import daolsecuritiesLogo from "../../assets/images/logo/daolsecurities.jpg";
import lottecardLogo from "../../assets/images/logo/lottecard.jpg";
import lottecapLogo from "../../assets/images/logo/lottecap.jpg";
import kdbcLogo from "../../assets/images/logo/kdbc.jpg";
import samsungcardLogo from "../../assets/images/logo/samsungcard.jpg";
import shinhancardLogo from "../../assets/images/logo/shinhancard.jpg";
import shcapLogo from "../../assets/images/logo/shcap.jpg";
import ajuibLogo from "../../assets/images/logo/ajuib.jpg";
import ajucapitalLogo from "../../assets/images/logo/ajucapital.jpg";
import hankookcapitalLogo from "../../assets/images/logo/hankookcapital.jpg";
import hyundaicardLogo from "../../assets/images/logo/hyundaicard.jpg";
import hyundaicapitalLogo from "../../assets/images/logo/hyundaicapital.jpg";
import capitalmLogo from "../../assets/images/logo/capital-m.jpg";
import hanacapitalLogo from "../../assets/images/logo/hanacapital.jpg";
import sbisbLogo from "../../assets/images/logo/sbisb.jpg";
import acuonsavingsbankLogo from "../../assets/images/logo/acuonsavingsbank.jpg";
import kiwoomyesbankLogo from "../../assets/images/logo/kiwoomyesbank.jpg";
import prsbLogo from "../../assets/images/logo/prsb.jpg";
import goryosbLogo from "../../assets/images/logo/goryosb.jpg";
import kukjeLogo from "../../assets/images/logo/kukje.jpg";
import kuemhwabankLogo from "../../assets/images/logo/kuemhwabank.jpg";
import nybankLogo from "../../assets/images/logo/nybank.jpg";
import daolsbLogo from "../../assets/images/logo/daolsb.jpg";
import daemyungbankLogo from "../../assets/images/logo/daemyungbank.jpg";
import debecbankLogo from "../../assets/images/logo/debecbank.jpg";
import daishinLogo from "../../assets/images/logo/daishin.jpg";
import daeabankLogo from "../../assets/images/logo/daeabank.jpg";
import dbanksLogo from "../../assets/images/logo/d-banks.jpg";
import daehanbankLogo from "../../assets/images/logo/daehanbank.jpg";
import doublebankLogo from "../../assets/images/logo/doublebank.jpg";
import thekbankLogo from "../../assets/images/logo/thekbank.jpg";
import dysbankLogo from "../../assets/images/logo/dysbank.jpg";
import dongwonjeilbankLogo from "../../assets/images/logo/dongwonjeilbank.jpg";
import dreamsbLogo from "../../assets/images/logo/dreamsb.jpg";
import raonsbLogo from "../../assets/images/logo/raonsb.jpg";
import samilbankLogo from "../../assets/images/logo/samilbank.jpg";
import moasbLogo from "../../assets/images/logo/moasb.jpg";
import mkbLogo from "../../assets/images/logo/mkb.jpg";
import barosavingsLogo from "../../assets/images/logo/barosavings.jpg";
import bulimbankLogo from "../../assets/images/logo/bulimbank.jpg";
import samjungsavingsbankLogo from "../../assets/images/logo/samjungsavingsbank.jpg";
import samhosbLogo from "../../assets/images/logo/samhosb.jpg";
import sangsanginsbLogo from "../../assets/images/logo/sangsanginsb.jpg";
import sangsanginplussbLogo from "../../assets/images/logo/sangsanginplussb.jpg";
import serambankLogo from "../../assets/images/logo/serambank.jpg";
import centralbankLogo from "../../assets/images/logo/centralbank.jpg";
import soulbrainsbLogo from "../../assets/images/logo/soulbrainsb.jpg";
import smartbankLogo from "../../assets/images/logo/smartbank.jpg";
import skysbLogo from "../../assets/images/logo/skysb.jpg";
import estarbankLogo from "../../assets/images/logo/estarbank.jpg";
import shinhansavingsLogo from "../../assets/images/logo/shinhansavings.jpg";
import asanbankLogo from "../../assets/images/logo/asanbank.jpg";
import angukbankLogo from "../../assets/images/logo/angukbank.jpg";
import anyangbankLogo from "../../assets/images/logo/anyangbank.jpg";
import yjbankLogo from "../../assets/images/logo/yjbank.jpg";
import yegaramsbLogo from "../../assets/images/logo/yegaramsb.jpg";
import osungbankLogo from "../../assets/images/logo/osungbank.jpg";
import o2bankingLogo from "../../assets/images/logo/o2banking.jpg";
import wooleebankLogo from "../../assets/images/logo/wooleebank.jpg";
import wooriLogo from "../../assets/images/logo/woori.jpg";
import welcomebankLogo from "../../assets/images/logo/welcomebank.jpg";
import kusbLogo from "../../assets/images/logo/kusb.jpg";
import yuantasavingsLogo from "../../assets/images/logo/yuantasavings.jpg";
import ycbankLogo from "../../assets/images/logo/ycbank.jpg";
import insungsavingsbankLogo from "../../assets/images/logo/insungsavingsbank.jpg";
import incheonbankLogo from "../../assets/images/logo/incheonbank.jpg";
import choeunbankLogo from "../../assets/images/logo/choeunbank.jpg";
import chbankLogo from "../../assets/images/logo/chbank.jpg";
import jinjubankLogo from "../../assets/images/logo/jinjubank.jpg";
import charmbankLogo from "../../assets/images/logo/charmbank.jpg";
import cheongjubankLogo from "../../assets/images/logo/cheongjubank.jpg";
import pepperbankLogo from "../../assets/images/logo/pepperbank.jpg";
import ptbankLogo from "../../assets/images/logo/ptbank.jpg";
import hanasavingsLogo from "../../assets/images/logo/hanasavings.jpg";
import koreainvestmentLogo from "../../assets/images/logo/koreainvestment.jpg";
import hsbankLogo from "../../assets/images/logo/hsbank.jpg";
import hanwhasbankLogo from "../../assets/images/logo/hanwhasbank.jpg";
import ehkbankLogo from "../../assets/images/logo/ehkbank.jpg";
import bnksbLogo from "../../assets/images/logo/bnksb.jpg";
import cksavingsbankLogo from "../../assets/images/logo/cksavingsbank.jpg";
import idbsbLogo from "../../assets/images/logo/idbsb.jpg";
import dhsavingsbankLogo from "../../assets/images/logo/dhsavingsbank.jpg";
import hbsbLogo from "../../assets/images/logo/hbsb.jpg";
import ibksbLogo from "../../assets/images/logo/ibksb.jpg";
import jtLogo from "../../assets/images/logo/jt.jpg";
import jtchinaebankLogo from "../../assets/images/logo/jtchinae-bank.jpg";
import kbsavingsLogo from "../../assets/images/logo/kbsavings.jpg";
import mssbLogo from "../../assets/images/logo/mssb.jpg";
import nhsavingsbankLogo from "../../assets/images/logo/nhsavingsbank.jpg";
import oksavingsbankLogo from "../../assets/images/logo/oksavingsbank.jpg";
import osbLogo from "../../assets/images/logo/osb.jpg";
import hisntmLogo from "../../assets/images/logo/hisntm.jpg";
import iambankLogo from "../../assets/images/logo/iambank.jpg";
import shinhyupLogo from "../../assets/images/logo/shinhyup.jpg";

export const bankLogo = {
    "KEB하나은행": kebhanaBankLogo,
    "SC제일은행": scBankLogo,
    "KB국민은행": kbstarBankLogo,
    "신한은행": shinhanBankLogo,
    "우리은행": wooriBankLogo,
    "한국시티은행": citiBankLogo,
    "IBK기업은행": ibkBankLogo,
    "KDB산업은행": kdbBankLogo,
    "한국수출입은행": koreaeximLogo,
    "BNK경남은행": knbankLogo,
    "광주은행": kjbankLogo,
    "BNK부산은행": busanbankLogo,
    "전북은행": jbbankLogo,
    "제주은행": jejubankLogo,
    "NH농협": nhBankLogo,
    "SH수협": suhyupBankLogo,
    "MG새마을금고": mgBankLogo,
    "ABL생명": abllifeLogo,
    "DB생명": idblifeLogo,
    "DBG생명": dgbfnlifeLogo,
    "오렌지라이프": orangelifeLogo,
    "KDB생명": kdblifeLogo,
    "PCA생명": pcakoreaLogo,
    "교보생명": kyoboLogo,
    "동양생명": myangelLogo,
    "메트라이프생명": metlifeLogo,
    "미래에셋생명": miraeassetLogo,
    "삼성생명": samsunglifeLogo,
    "신한생명": shinhanlifeLogo,
    "신협": shinhyupLogo,
    "처브라이프생명보험": chubblifeLogo,
    "푸르덴셜생명": prudentialLogo,
    "한화생명": hanwhalifeLogo,
    "흥국생명": heungkuklifeLogo,
    "DB손해보험": directdbLogo,
    "KB손해보험": kbinsureLogo,
    "MG손해보험": mggeneralinsLogo,
    "SGI서울보증": sgicLogo,
    "롯데손해보험": lotteinsLogo,
    "메리츠화재": meritzfireLogo,
    "삼성화재": samsungfireLogo,
    "한화손해보험": hwgeneralinsLogo,
    "현대해상": hiLogo,
    "흥국화재해상보험": heungkukfireLogo,
    "우리금융캐피탈": woorifcapitalLogo,
    "BC카드": bccardLogo,
    "KB캐피탈": kbcapitalLogo,
    "다올투자증권": daolsecuritiesLogo,
    "롯데카드": lottecardLogo,
    "롯데캐피탈": lottecapLogo,
    "산은캐피탈": kdbcLogo,
    "삼성카드": samsungcardLogo,
    "신한카드": shinhancardLogo,
    "신한캐피탈": shcapLogo,
    "아주IB투자": ajuibLogo,
    "아주캐피탈": ajucapitalLogo,
    "한국캐피탈": hankookcapitalLogo,
    "현대카드": hyundaicardLogo,
    "현대캐피탈": hyundaicapitalLogo,
    "엠캐피탈": capitalmLogo,
    "하나캐피탈": hanacapitalLogo,
    "SBI저축은행": sbisbLogo,
    "애큐온저축은행": acuonsavingsbankLogo,
    "키움YES저축은행": kiwoomyesbankLogo,
    "푸른저축은행": prsbLogo,
    "고려저축은행": goryosbLogo,
    "국제저축은행": kukjeLogo,
    "금화저축은행": kuemhwabankLogo,
    "남양저축은행": nybankLogo,
    "다올저축은행": daolsbLogo,
    "대명저축은행": daemyungbankLogo,
    "대백저축은행": debecbankLogo,
    "대신저축은행": daishinLogo,
    "대아저축은행": daeabankLogo,
    "대원저축은행": dbanksLogo,
    "대한저축은행": daehanbankLogo,
    "더블저축은행": doublebankLogo,
    "더케이저축은행": thekbankLogo,
    "동양저축은행": dysbankLogo,
    "동원제일저축은행": dongwonjeilbankLogo,
    "드림저축은행": dreamsbLogo,
    "라온저축은행": raonsbLogo,
    "머스트삼일저축은행": samilbankLogo,
    "모아저축은행": moasbLogo,
    "민국저축은행": mkbLogo,
    "바로저축은행": barosavingsLogo,
    "부림저축은행": bulimbankLogo,
    "삼정저축은행": samjungsavingsbankLogo,
    "삼호저축은행": samhosbLogo,
    "상상인저축은행": sangsanginsbLogo,
    "상상인플러스저축은행": sangsanginplussbLogo,
    "세람저축은행": serambankLogo,
    "센트럴저축은행": centralbankLogo,
    "솔브레인저축은행": soulbrainsbLogo,
    "스마트저축은행": smartbankLogo,
    "스카이저축은행": skysbLogo,
    "스타저축은행": estarbankLogo,
    "신한저축은행": shinhansavingsLogo,
    "아산저축은행": asanbankLogo,
    "아이엠뱅크": iambankLogo,
    "안국저축은행": angukbankLogo,
    "안양저축은행": anyangbankLogo,
    "영진저축은행": yjbankLogo,
    "예가람저축은행": yegaramsbLogo,
    "오성저축은행": osungbankLogo,
    "오투저축은행": o2bankingLogo,
    "우리저축은행": wooleebankLogo,
    "우리금융저축은행": wooriLogo,
    "웰컴저축은행": welcomebankLogo,
    "유니온저축은행": kusbLogo,
    "유안타저축은행": yuantasavingsLogo,
    "융창저축은행": ycbankLogo,
    "인성저축은행": insungsavingsbankLogo,
    "인천저축은행": incheonbankLogo,
    "조은저축은행": choeunbankLogo,
    "조흥저축은행": chbankLogo,
    "진주저축은행": jinjubankLogo,
    "참저축은행": charmbankLogo,
    "청주저축은행": cheongjubankLogo,
    "페퍼저축은행": pepperbankLogo,
    "평택저축은행": ptbankLogo,
    "하나저축은행": hanasavingsLogo,
    "한국투자저축은행": koreainvestmentLogo,
    "한성저축은행": hsbankLogo,
    "한화저축은행": hanwhasbankLogo,
    "흥국저축은행": ehkbankLogo,
    "BNK저축은행": bnksbLogo,
    "CK저축은행": cksavingsbankLogo,
    "DB저축은행": idbsbLogo,
    "DH저축은행": dhsavingsbankLogo,
    "HB저축은행": hbsbLogo,
    "IBK저축은행": ibksbLogo,
    "JT저축은행": jtLogo,
    "JT친애저축은행": jtchinaebankLogo,
    "KB저축은행": kbsavingsLogo,
    "MS저축은행": mssbLogo,
    "NH저축은행": nhsavingsbankLogo,
    "OK저축은행": oksavingsbankLogo,
    "OSB저축은행": osbLogo,
    "SNT저축은행": hisntmLogo,

}
