import { configureStore, createSlice } from '@reduxjs/toolkit';
import storage from "redux-persist/lib/storage";
import { combineReducers } from "@reduxjs/toolkit";
import { persistReducer } from "redux-persist";

let searchResult = createSlice({
    name: "searchResult",
    initialState: [],
    reducers: {
        getSearchValue(state, action) {
            if(action.payload.status === "init") {
                return state = action.payload.data;
            } else {
                return state = state.concat(action.payload.data);
            }
        },
    }
});

let searchAreas = createSlice({
    name: "searchAreas",
    initialState: [],
    reducers: {
        getSearchAreas(state, action) {
            return state = action.payload;
        },
    }
});

let searchCount = createSlice({
    name: "searchCount",
    initialState: 0,
    reducers: {
        getSearchCount(state, action) {
            return state = action.payload;
        },
    }
});

let searchPage = createSlice({
    name: "searchPage",
    initialState: 0,
    reducers: {
        getSearchPage(state, action) {
            return state = action.payload;
        },
    }
});

const persistConfig = {
    key : "root",
    storage,
    whitelist : ["searchResult", "searchAreas", "searchCount"]
}

const rootReducer = combineReducers({
    searchResult : searchResult.reducer,
    searchAreas : searchAreas.reducer,
    searchCount : searchCount.reducer,
    searchPage : searchPage.reducer
})
const persistedReducer = persistReducer(persistConfig, rootReducer)


export let { getSearchValue } = searchResult.actions;
export let { getSearchAreas} = searchAreas.actions;
export let { getSearchCount} = searchCount.actions;
export let { getSearchPage} = searchPage.actions;

const store = configureStore({
    reducer: persistedReducer,
    middleware: (defaultMiddleware) =>
        defaultMiddleware({
            serializableCheck: false
        })
})

export default store;
