import React from 'react';
import styled from "styled-components";
import LoopSlide from "./LoopSlide";

const LoopWrapper = () => {
    return(
        <LoopWrapperContaier>
            <LoopSlide />
            <LoopSlide />
        </LoopWrapperContaier>
    )
}

const LoopWrapperContaier = styled.div`
  display:flex;
  align-items: center;
  gap:0 90px;
  padding:20px 0;
  box-shadow:inset 0 0 15px 0 rgba(0,0,0,.3);
  overflow:hidden;
  
  & .loop-wrap {
    & .loop-slide {
      animation :loop 30s infinite linear forwards;
    }
  }
  
  @keyframes loop {
    from { left:0%; }
    to { left:calc(-100% - 90px); }
  }
  
  @media (max-width:999px){
    gap:0 42px;
  }
`


export default LoopWrapper;