import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import RoundButton from "../components/common/RoundButton";

import AOS from "aos";
import "aos/dist/aos.css"
import Article6 from "../assets/images/article6.svg"
import SelectBox from "../components/common/SelectBox";
import {useMediaQuery} from "react-responsive";
import SelectLocation from "../components/layout/SelectLocation";
import {useSelector} from "react-redux";
import ListCounselor from "./ListCounselor";

const FindCounselor = () => {

    // 모바일인지 체크
    let isMobile = useMediaQuery({
        query : "(max-width:999px)",
    });
    useEffect(()=>{
        AOS.init();
    },[])

    let searchList = useSelector((state)=> {return state.searchResult});
    let [searchResult, setSearchResult] = useState([]); // 리스트

    useEffect(()=>{
        setSearchResult(searchList)
    },[searchList])


    return(
        <>
            <FindCounselorWrap>
                <div className={"find-cover"}>
                    <div className={"bgColor"}>
                        <div
                            className={"text-wrap"}
                            data-aos={"fade-up"}
                            data-aos-duration={"1000"}
                        >
                            <h1 className={"title"}>지역별 상담사 찾기</h1>
                            <div className={"desc"}>필요한 지역의 상담사를 찾아보세요.</div>
                        </div>
                    </div>
                    <div className={"select-content"}>
                        <div className={"find-select"}>
                            <SelectLocation
                                size={!isMobile ? "middle" : "mobile"}
                                bgColor={"black"}
                                color={"white"}
                                text={"상담사 찾기"}
                                className={isMobile && "mo"}
                                isMobile={isMobile}
                                searchArea={""}
                            />
                        </div>
                        <div
                            className={"article6"}
                            data-aos={"fade-up"}
                            data-aos-duration={"1000"}
                        ><object data={Article6}/></div>
                    </div>
                </div>
            </FindCounselorWrap>
        </>

    )
}

const FindCounselorWrap = styled.div`
  padding:0 0 150px;
  
  & .bgColor {
    position:relative;
    background-color:#0185FF;
    min-height:556px;
    max-width:1200px;
    margin:0 auto;
    border-radius:0 0 50px 50px;
    
    & .text-wrap {
      position:absolute;
      top:50%;
      left:50%;
      transform:translate(-50%, -50%);
      color:#fff;
      text-align:center;
      
      & .title {
        font-size:40px;
        font-weight:900;
        line-height:59px;
      }
      
      & .desc {
        font-size:20px;
        font-weight:400;
        line-height:36px;
      }
    }
    
    &::after {
      content:'';
      display:block;
      position:absolute;
      top:0;
      left:0;
      width:100%;
      height:184px;
      background:linear-gradient(0deg, rgba(0,0,0,0) 0%, rgba(255,255,255,1) 100%);
    }
  }
  
  & .select-content {
    position:relative;
    max-width:1200px;
    margin:0 auto;
    padding:225px 0 150px;
    text-align:center;
    
    & .find-select { margin:20px 0 0; }
    
    & .select-wrap {
      display:flex;
      justify-content:center;
      align-items: center;
      gap:0 45px;
      
      & .react-select__control {
        border:1px solid #000;
        box-shadow:4px 4px 2px -2px rgba(0,0,0,.3);
      }
      & .selected .react-select__control {
        background-color:#000;
      }
    }
    
    & .button-wrap {
      display: flex;
      justify-content: center;
      align-items:center;
      margin:28px 0 0;
      font-size:20px;
      font-weight:700;

      & [color="blue"], [color="white"] {
        position:relative;

        & a {
          transition: all .3s;
          background-color:#fff;

          & span { transition: all .3s; }
          @media (hover: hover) and (pointer: fine) {
            &:hover { background-color:transparent; }
            &:hover span { color:#fff; }
          }
        }
        &::after {
          content:'';
          display:block;
          position:absolute;
          top:0;
          left:0;
          width:100%;
          height:100%;
          background-image: linear-gradient(90deg, rgba(146,255,205,1) 0%, rgba(37,212,251,1) 100%);
          border-radius:25px;
          z-index:-1;
        }
      }
      & [color="white"] a { background-color:#000; }

      & .main-color {
        background-image: linear-gradient(90deg, rgba(146,255,205,1) 0%, rgba(37,212,251,1) 100%);
        background-clip: text;
        -webkit-background-clip: text;
        color:transparent;
        font-weight:700;
      }
    }
    
    & .article6 {
      position:absolute;
      top:-30%;
      left:50%;
      transform:translate(-50%, 0);
    }
  }
  
  @media (max-width:999px){
    & .find-cover {
      position:relative;
      padding:83px 0 40px;
      background-color:#0185FF;
      border-radius:0 0 50px 50px;

      & .bgColor {
        max-width:100%;
        min-height:auto;
        border-radius:0;
        background-color:transparent;

        & .text-wrap {
          position:static;
          margin:40px 0 0;
          transform:none;

          & .title {
            font-size:32px;
            line-height:47px;
          }

          & .desc {
            font-size:15px;
            line-height:36px;
          }
        }

        &::after { display:none; }
      } // .bgColor

      & .select-content {
        display:flex;
        flex-direction: column;
        padding:0;
        & .find-select{
          *position:relative;
          order:2;
          & .select-wrap {
            flex-direction: column;
            gap:10px 0;
            & .react-select__control {
              border:0;
              box-shadow:none;
            }
          }
          
          & .button-wrap {
            position:absolute;
            top:calc(100% + 80px);
            left:50%;
            transform:translate(-50%, -50%);
            margin:0;
          }
        }
        & .article6 {
          position:static;
          order:1;
          transform:none;
          & object {
            max-width:300px;
          }
        }
      }
    }
    
    & .button-wrap {
      & .mo {
        text-align:center;
        & a {
          font-size:14px;
          line-height:21px;
        }
      }
    }
  }
  
  
`

export default FindCounselor;