import React from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";

const size = {
    default : {
        width:"200px",
        height:"50px",
        borderRadius:"25px",
    },
    middle : {
        width:"200px",
        height:"41px",
        borderRadius:"25px",
    },
    small : {
        width: "82px",
        height: "30px",
        borderRadius:"15px",
    },
    mini : {
        width : "66px",
        height : "41px",
        borderRadius:"5px",
    },
    mobile : {
        width : "108px",
        height : "40px",
        borderRadius:"25px",
    }
}
const color = {
    white : { color:"#fff" },
    black : { color:"#000" },
    blue : { color : "#0185FF" },
    main : {
        backgroundImage: "linear-gradient(90deg, rgba(146,255,205,1) 0%, rgba(37,212,251,1) 100%)",
        backgroundClip: "text",
        color:"transparent",
    }
}

const background = {
    black : { backgroundColor : "#000"},
    white : { backgroundColor : "#fff"},
    main : { backgroundImage : "linear-gradient(90deg, rgba(146,255,205,1) 0%, rgba(37,212,251,1) 100%)" }
}

const border = {
    black : { border: "2px solid #000" },
    main : { border:"2px solid linear-gradient(90deg, rgba(146,255,205,1) 0%, rgba(37,212,251,1) 100%)" }
}

const RoundButton = ({
         bgColor,
         size,
         color,
         border,
         className,
         link,
         target = false,
         onClick,
         children,
    }) => {
    return (
        <ButtonWrap
            $bgColor={bgColor}
            size={size}
            color={color}
            border={border}
            className={className}
        >
            <Link
                to={link}
                onClick={onClick}
                target={target ? "_blank" : "_self"}
            >{children}</Link>
        </ButtonWrap>
    );
}

const ButtonWrap = styled.div`
  
    & a {
      display:inline-flex;
      justify-content: center;
      align-items:center;
      ${(props)=> background[props.$bgColor]}
      ${(props)=> color[props.color]}
      ${(props)=> size[props.size]}
      ${(props)=> border[props.border]}
      -webkit-backgroundClip: text;
      cursor:pointer;
    }
  
  
  
`

export default RoundButton;