import React from 'react';
import styled from "styled-components";

import Nhbank from "../../assets/images/loop/nhbank.png" // 농협은행
import Ibk from "../../assets/images/loop/ibk.png" // 기업은행
import Kebhana from "../../assets/images/loop/kebhana.png" // 하나은행
import Kbstar from "../../assets/images/loop/kbstar.png" // 국민은행
import Shinhan from "../../assets/images/loop/shinhan.png" // 신한은행
import Standardchartered from "../../assets/images/loop/standardchartered.png" // SC제일은행
import Wooribank from "../../assets/images/loop/wooribank.png" // 우리은행
import Citibank from "../../assets/images/loop/citibank.png" // 씨티뱅크
import Kfcc from "../../assets/images/loop/kfcc.png" // MG 새마을금고
import Suhyupbank from "../../assets/images/loop/suhyup-bank.png" // 수협은행
import Openbank from "../../assets/images/loop/openbank.png" // 신협은행
import Busanbank from "../../assets/images/loop/busanbank.png" // 부산은행
import Dgb from "../../assets/images/loop/dgb.png" // DGB대구은행
import Kjbank from "../../assets/images/loop/kjbank.png" // 광주은행

const LoopSlide = () => {
    return (
        <LoopSlideWrap className={"loop-wrap"}>
            <ul className={"loop-slide"}>
                <li><img src={Nhbank}/></li>
                <li><img src={Ibk}/></li>
                <li><img src={Kebhana}/></li>
                <li><img src={Kbstar}/></li>
                <li><img src={Shinhan}/></li>
                <li><img src={Standardchartered}/></li>
                <li><img src={Wooribank}/></li>
                <li><img src={Citibank}/></li>
                <li><img src={Kfcc}/></li>
                <li><img src={Suhyupbank}/></li>
                <li><img src={Openbank}/></li>
                <li><img src={Busanbank}/></li>
                <li><img src={Dgb}/></li>
                <li><img src={Kjbank}/></li>
            </ul>
        </LoopSlideWrap>
    );
}

const LoopSlideWrap = styled.div`
  & ul {
    position:relative;
    display:flex;
    align-items:center;
    gap:0 90px;
    
    & li {
      width:100%;
      min-width:130px;
      max-width:180px;
      & img {
        display:block;
        width:100%;
        height:auto;
      }
    }
  }
  
  @media (max-width:999px){
    & ul {
      gap:0 42px;
      & li {
        min-width:90px;
        max-width:90px;
      }
    }
  }
`

export default LoopSlide;
