import './App.css';
import Header from "./components/layout/Header";
import {Outlet, useLocation} from "react-router-dom";
import Footer from "./components/layout/Footer";
import {useEffect, useRef, useState} from "react";

let prev = "";
function App({scrollContent}) {
    let pathname = useLocation()
    let [service, setService] = useState(false);
    useEffect(()=>{
        if( pathname.pathname === "/" && service ){
            scrollContent.current.scrollIntoView({behavior:"smooth"});
            setService(false)
        }
        else {
            if( pathname.pathname !== prev ){
                window.scrollTo(0, 0);
            }
        }
        prev = pathname.pathname
    },[pathname])
  return (
    <div className="App">
        <Header
            scrollContent={scrollContent}
            setService={setService}
            pathname={pathname}
        />
            <Outlet />
        <Footer/>
    </div>
  );
}

export default App;
