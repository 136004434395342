import React from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {useMediaQuery} from "react-responsive";

const Support = () => {
    let isMobile = useMediaQuery({
        query : "(max-width:999px)",
    });
    return(
        <SupportWrap>
            <div className={"support-cover"}>
                <div className={"text-wrap"}>
                    <h1 className={"title"}>고객센터</h1>
                    {
                        !isMobile
                            ? <div className={"desc"}>
                                궁금하신 점은 아래 카카오톡 채널 또는 이메일로 남겨주시면<br/>
                                최대한 빠르게 답변 드리겠습니다.
                            </div>
                            : <div className={"desc"}>
                                궁금하신 점은 아래<br/>
                                카카오톡 채널 또는 이메일로 남겨주시면<br/>
                                최대한 빠르게 답변 드리겠습니다.
                            </div>
                    }

                    <div className={"work-time"}>
                        고객센터 운영 시간<br/>
                        월-금 9:00 ~ 18:00 (공휴일 제외)
                    </div>
                </div>
                <div className={"button-wrap"}>
                    <div className={"content"}>
                        <h3 className={"title"}>론앤톡 카카오톡 채널</h3>
                        <Link to={"http://pf.kakao.com/_xbJXtG/chat"} className={"kakao button"}>카카오톡 채널 문의</Link>
                    </div>
                    <div className={"content"}>
                        <h3 className={"title"}>론앤톡 이메일</h3>
                        <div
                            className={"email button"}
                            onClick={(e)=>{
                                navigator.clipboard.writeText("loanandtalk@gmail.com");
                                alert("이메일이 복사 되었습니다.");
                            }}
                        >loanandtalk@gmail.com</div>
                    </div>
                </div>
            </div>
        </SupportWrap>
    )
}

const SupportWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width:100%;
  height:100vh;
  
  & .support-cover {
    max-width:400px;
    min-width:340px;
  }
  
  & .text-wrap {
    padding:0 0 30px;
    & .title {
      font-size:24px;
      font-weight:700;
      line-height:36px;
    }
    & .desc {
      margin:20px 0 0;
      font-size:16px;
      font-weight:700;
      line-height:24px;
    }
    & .work-time {
      margin:20px 0 0;
      font-size:16px;
      font-weight:400;
      line-height:24px;
    }
  }
  
  & .button-wrap {
    padding:30px 0 0;
    border-top:1px solid #909090;
    
    & .content {
      & .title {
        font-size:16px;
        font-weight:700;
        line-height:24px;
      }
      
      & .button {
        display:flex;
        justify-content: center;
        align-items: center;
        width:100%;
        height:43px;
        border-radius:10px;
        margin:5px 0 0;
        font-size:16px;
        font-weight:700;
        line-height:24px;
        cursor:pointer;
      }
      
      & a.kakao {
        background-color:#FEE500;
        color:#191919;
      }
      & .email {
        background-color:#000;
        color:#fff;
      }
    }
    
    & .content + .content {
      margin:24px 0 0;
    }
  }

  @media (max-width:999px){
    justify-content: flex-start;
    padding:84px 25px 150px;
    margin:60px 0 0;
    height:auto;
    
    & .support-cover{
      min-width:100%;
      & .button-wrap { 
        padding:30px 13px 0;
        & .content {
          & .title { 
            font-size:14px;
            color:#535353;
            padding:0 0 0 11px;
          }
        }
      }
    }
  }
`

export default Support;