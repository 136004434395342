import React, {useEffect} from 'react';
import styled from "styled-components";
import Select from "react-select";

import Indicator from "../../assets/images/indicator.svg";

const SelectBox = ({
       option,
       className,
       placeholder,
       onChange,
       defaultValue,
       value,
       noOptionsMessage,
   }) => {
    return (
        <SelectWrapper
            options={option}
            noOptionsMessage={()=>noOptionsMessage}
            className={className}
            placeholder={placeholder}
            onChange={onChange}
            defaultValue={defaultValue}
            value={value}
            isSearchable={false}
            // menuIsOpen={true}
        ></SelectWrapper>
    )
}

const SelectWrapper = styled(Select).attrs({
    classNamePrefix: 'react-select',
})`
  .react-select__control {
    width: 170px;
    height: 41px;
    border: 0;
    display: flex;
    text-align: center;
    cursor: pointer;
    padding: 8px 12px;
    box-sizing: border-box;
    min-height: auto;
    font-size: 14px;
    flex-wrap: nowrap;
    border-radius:5px;
    font-weight:700;
  }

  .react-select__control--is-focused,
    //.react-select__control:hover,
  .react-select__control:focus {
    background-color:#000;
    transition: all .6s;
    border:0;
  }

  .react-select__control--is-focused {
    box-shadow: none;
  }

  .react-select__placeholder {
    font-weight: 500;
    text-align: left;
  }

  .react-select__value-container {
    padding: 0;
  }

  .react-select__input-container {
    margin: 0;
    padding: 0;
    & input { width:0;height:0; }
  }

  & .react-select__single-value {
    background-image: linear-gradient(90deg, rgba(146,255,205,1) 0%, rgba(37,212,251,1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color:transparent;
  }

  .react-select__menu {
    width: 170px;
    background-image:linear-gradient(#fff, #fff), linear-gradient(to right, rgba(146,255,205,1) 0%, rgba(37,212,251,1) 100%);
    background-origin: border-box;
    background-clip: content-box, border-box;
    border-image-slice:1;
    box-shadow: 0 4px 4px 0 rgba(0, 0, 0, .3);
    font-weight: 400;
    text-align: center;
    font-size: 14px;
    margin:0;
    padding:8px 0 0;
    border-radius:0;
    border:0;
  }

  .react-select__option {
    background-color: transparent; /* option 배경색 */
    color: black; /* option 텍스트 색상 */
    text-align: left;
    padding:8px 16px;
  }

  .react-select__option:hover {
    background-color: #0185FF;
    color: #FFF;
  }

  .react-select__option--is-selected {
    background-color: #0185FF; /* 클릭된 option 배경색 */
    color: white; /* 클릭된 option 텍스트 색상 */
  }

  .react-select__option--is-focused {
    border: 0;
    *color: #fff; /* hover 상태의 option 텍스트 색상 */
  }

  .react-select__placeholder {
    background-image: linear-gradient(90deg, rgba(146,255,205,1) 0%, rgba(37,212,251,1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color:transparent;
    font-weight: 700;
  }

  .react-select__indicator-separator {
    display: none;
  }

  .react-select__indicator {
    padding: 0;
    width: 15px;
    height:10px;
    background:url(${Indicator}) no-repeat center/contain;
    & svg { display:none; }
  }

  &.unBorder {
    & .react-select__control {
      border: 0;
      //width: 120px;
    }
  }

  & .selected .react-select__control {
    background-color:#000;
  }
  
  .react-select__single-value { text-align:left; }
`

export default SelectBox;